import React from "react";
import { Login, LoginForm } from "react-admin";
import { Link } from "react-router-dom";
import { Box, Typography, Container, Paper } from "@mui/material";

const CustomLogin = (props) => {
  return (
    <Login
      {...props}
      sx={{
        backgroundColor: "#F0F4F8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="xs"
        disableGutters
        sx={{ marginTop: 0, marginBottom: 0 }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ color: "#1976d2", fontWeight: "bold" }}
          >
            Welcome Back
          </Typography>
          <LoginForm />
          <Box textAlign="center" mt={2}>
            <Typography variant="body2">
              <Link
                to="/forgot-password"
                style={{ textDecoration: "none", color: "#1976d2" }}
              >
                Forgot Password?
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Login>
  );
};

export default CustomLogin;
