import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  // Skip Authorization for public routes
  const publicRoutes = ["forgot-password", "reset-password"];
  const isPublicRoute = publicRoutes.some((route) => url.includes(route));
  if (!isPublicRoute) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const { access_token } = auth;

    options.headers.set("Authorization", `Bearer ${access_token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

/**
 * Current behaviour: react-admin handles List filter by storing them on the url
 * Problem: If we use a tabbed view to show more than one filtered List on the same page, they all try to use the url to handle filters,
 * so filters for different views get mixed
 * Solution: we qualify the filter names using the entity names 'entity##column', so we can handle filters for different lists on the same url
 */
const processQualifiedFilters = (resource, params) => {
  if (params.filter) {
    for (const column in params.filter) {
      const [table, rawcol] = column.split("##");

      if (rawcol) {
        if (table === resource) {
          params.filter[rawcol] = params.filter[column];
        }

        delete params.filter[column];
      }
    }
  }
};

const rest_provider = simpleRestProvider(window.ENV.API_URL, httpClient);

export const dataProvider = new Proxy(rest_provider, {
  get(target, name) {
    return function (resource, params) {
      processQualifiedFilters(resource, params);
      return target[name](resource, params);
    };
  },
});
