import React from "react";
import { TextField, NumberField, TextInput, NumberInput } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const allFields = [
  { name: "id", component: TextField, props: { sortable: false } },
  { name: "userId", component: NumberField, props: { sortable: false } },
  { name: "firstName", component: TextField, props: { sortable: false } },
  { name: "lastName", component: TextField, props: { sortable: false } },
  { name: "towerId", component: TextField, props: { sortable: false } },
  { name: "email", component: TextField, props: { sortable: false } },
];

const filters = [
  <NumberInput label="User" source="userId" alwaysOn key={1} />,
  <TextInput label="Tower" source="towerId" alwaysOn key={2} />,
  <TextInput label="Email" source="email" alwaysOn key={3} />,
];

export default function HostTowersList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "host-towers",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="host-towers"
      {...props}
      filters={filters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
