import React from "react";
import { TextField, DateField, TextInput, SelectInput } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const actionChoices = [
  { id: "activated", name: "Activated" },
  { id: "deactivated", name: "Deactivated" },
];

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  {
    name: "host_id",
    component: TextField,
    props: { sortable: true, label: "Host ID" },
  },
  {
    name: "tower_id",
    component: TextField,
    props: { sortable: true, label: "Tower ID" },
  },
  {
    name: "action",
    component: TextField,
    props: { sortable: true, label: "Action" },
  },
  {
    name: "email",
    component: TextField,
    props: { sortable: true, label: "Host Email" },
  },
  {
    name: "timestamp",
    component: DateField,
    props: { showTime: true, sortable: true, label: "Timestamp" },
  },
];

// Filters for searching logs
const filters = [
  <TextInput label="Host ID" source="host_id" alwaysOn key={1} />,
  <TextInput label="Tower ID" source="tower_id" alwaysOn key={2} />,
  <SelectInput
    label="Action"
    source="action"
    choices={actionChoices}
    alwaysOn
    key={3}
  />,
  <TextInput label="Host Email" source="email" alwaysOn key={4} />,
];

export default function HostTowerStatusLogsList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "host-tower-status-logs",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="host-tower-status-logs"
      {...props}
      filters={filters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
