import { Box } from "@mui/material";
import { Settings } from "../utils/settings";
import { debugInfo } from "../theme/styles";

export const Debug = (props) => {
  if (!Settings.debugging()) {
    return null;
  }

  return (
    <Box sx={debugInfo} {...props} className="Debug">
      {props.children}
    </Box>
  );
};
