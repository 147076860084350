import React, { useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Paper,
} from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dataProvider.create("users/forgot-password", { data: { email } });
      notify("Check your email for reset instructions", { type: "success" });
    } catch (error) {
      // Ignore the `id` error and display any other relevant errors
      if (error.message === "ra.notification.data_provider_error") {
        notify("Check your email for reset instructions", { type: "success" });
      } else {
        notify(error.message || "Error sending reset email", {
          type: "warning",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#05073F",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="xs" disableGutters>
        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Enter your email to receive reset instructions
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{ minWidth: 120 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
