import { Box, alpha } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";

export const Dot = (props) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  const sxBaseDot = {
    height: props.size + "px",
    width: props.size + "px",
    borderRadius: props.size / 2 + "px",
  };

  const sxCollapsed = collapsed
    ? Object.assign({}, sxBaseDot, {
        overflow: "hidden",
      })
    : {};

  const sxDotFlip = Object.assign({}, sxBaseDot, {
    position: "absolute",
    right: 0,
    backgroundColor: alpha(grey[500], 0.4),
    cursor: "pointer",
    zIndex: 100,
  });

  const sxDot = Object.assign({}, { position: "relative" }, sxCollapsed);

  return (
    <Box className="Dot" sx={sxDot}>
      <Box
        className="DotFlip"
        sx={sxDotFlip}
        onClick={() => setCollapsed((c) => !c)}
      ></Box>
      {props.children}
    </Box>
  );
};

Dot.defaultProps = {
  size: 30,
  collapsed: true,
};
